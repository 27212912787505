.filter-panel {

    --#{$prefix}accordion-active-bg: #{rgba(var(--#{$prefix}primary-rgb),
    0.05)
}

;

.accordion-item {
    .accordion-button {
        padding: 14px;
    }
}
}

//  product detail
.sticky-side-div {
    @media (min-width: 992px) {
        position: sticky;
        top: calc(#{$header-height} + #{$grid-gutter-width});
    }
}

.product-img-slider {
    .product-nav-slider {
        .nav-slide-item {
            border: 1px solid var(--#{$prefix}border-color);
            border-radius: $border-radius;
            padding: 0.5rem;
            cursor: pointer;
        }

        .swiper-slide-thumb-active {
            .nav-slide-item {
                background-color: var(--#{$prefix}light);
            }
        }
    }
}