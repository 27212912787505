//
// _real-estate.scss
//

#propertyFilters {

    // `xl` applies to large devices (desktops, less than 1200px)
    @media (max-width: 1399.98px) {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        z-index: 1009;
        padding: 0;
        width: 350px;

        [data-simplebar] {
            height: 100vh;
        }
    }
}

.real-estate-grid-widgets {

    .dropdown-real-estate {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .custom-toggle {
        position: absolute;
        top: 20px;
        left: 20px;
    }
}