// 
// _page-head.scss
// 

.page-title-box {
    padding: 0 0 20px; //$grid-gutter-width

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        font-weight: $font-weight-bold;
        font-size: 15px!important;
        text-transform: uppercase;
    }
}

[data-layout="horizontal"] {
    .page-title-box {
        padding: 1.2rem 0;
        background-color: transparent !important;
        border-bottom: none;
        border-top: none;
        box-shadow: none;
        margin: 0;

        @media (min-width: 1024.1px) {
            margin: -19px 0 0 0;
        }
    }
}

[data-layout="vertical"] {
    &[data-layout-style="detached"]{
        .page-title-box {
            padding: 1.2rem 0;
            background-color: transparent !important;
            border-bottom: none;
            border-top: none;
            box-shadow: none;
            margin: 0;
    
            @media (min-width: 1024.1px) {
                margin: -19px 0 0 0;
            }
        }
    }
}