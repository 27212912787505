//
// _pricing.scss
//

.pricing-box {
    li {
        &.active {
            &::marker {
                color: var(--#{$prefix}primary);
            }
        }
        }
}